import { useState } from "react";
import ReactDOM from "react-dom";
import { IoClose } from "react-icons/io5";
import { IoCopyOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";

const ContactModal = ({ showmodal, setshowmodal }) => {
  const { t, i18n } = useTranslation();
  return ReactDOM.createPortal(
    <div className="w-screen h-screen flex items-center z-40 fixed bg-[#00000086] left-0 top-0 justify-center">
      <div className="rounded-md p-3 w-[90%] md:w-[400px] animate__animated animate__fadeInUp  bg-white shadow-md">
        <div className="flex items-center justify-between">
          <h4 className="font-[600]">
            {t("contact.title")}
            {/* Contact Us */}
          </h4>
          <div
            className="flex items-center justify-center"
            onClick={() => setshowmodal(!showmodal)}
          >
            <IoClose size={22} />
          </div>
        </div>

        <div className="mt-4">
          <div className="">
            <div className="font-[600]">
              {/* Email */}
              {t("contact.email")}
            </div>
            <div className="flex mt-1 items-center border-[1px] rounded-md p-2 border-grey-800 justify-between">
              <a
                href="mailto: hello@chanels.io"
                className="underline text-[#3B82F6]"
              >
                hello@chanels.io
              </a>
              <div
                onClick={() => {
                  navigator.clipboard.writeText("hello@chanels.io");
                  window.alert("link copied");
                }}
                className="items-center justify-center"
              >
                <IoCopyOutline />
              </div>
            </div>
          </div>
          <div className="mt-2 mb-6">
            <div className="font-[600]">
              {t("contact.address")}
              {/* Address */}
            </div>
            <div className="flex mt-1items-center border-[1px] rounded-md p-2 border-grey-800 ">
              <div className="">
                Chanels.io,
                <br /> Industry Innovation Lab
                <br /> Carnegie Mellon University Africa <br /> Kigali, Rwanda
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("contactmodal")
  );
};

export default ContactModal;
