import Layout from "../../components/Layout";
import { BsArrowRightShort } from "react-icons/bs";
import {
  featuresData,
  recData,
  serviceData,
  solutionsData,
  specData,
} from "./data";
import "./style.css";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import CountCard from "../../components/CountCard";
import { useEffect, useState } from "react";
import { AiFillPlayCircle } from "react-icons/ai";
import { Carousel } from "react-responsive-carousel";
import HeroImg from "../../assets/png/OR7V1X1.png";
import HeroView from "../../assets/png/new_hero.png";
import TabView from "../../assets/png/tabview988.png";
import Slider from "react-slick";
// import BGImg from "../../assets/png/gel7.jpg";
import BGImg from "../../assets/png/v300batch2-nunoon-13.jpg";
// import CMUImg from "../../assets/png/contact-us.png";
import { useTranslation } from "react-i18next";
import ChangeNameModal from "../../components/ChangeNameModal";

const Landing = ({}) => {
  const { t, i18n } = useTranslation();
  const [shownotice, setshownotice] = useState(false);
  const solutions = t("solutions", { returnObjects: true });
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
    return () => {};
  }, []);
  // var settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   fade: true,
  //   autoplaySpeed: 2500,
  //   arrows: false,
  //   slidesToShow: 1,
  //   autoplay: true,
  //   slidesToScroll: 1,
  //   dots: false,
  // };

  useEffect(() => {
    // setTimeout(() => {
    //   setshownotice(true);
    // }, 10000);
    return () => {};
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 8000, //5000
  };
  const TestSlider = () => {
    return (
      <Slider
        {...settings}
        className="h-[auto] flex pt-[100px] items-center justify-center "
      >
        {solutionsData.map(({ id, title, desc }) => (
          <div
            className=" h-[380px] md:h-[600px] flex flex-col justify-start "
            key={id}
          >
            <div className="font-Kanit text-center md:text-start font-semibold text-[40px] leading-[45px] text-gray-800">
              {title}
            </div>
            <div className=" text-gray-800 text-center md:text-start pb-3 text-[16px] pt-3 font-light ">
              {desc}
            </div>
            <div className="flex items-center mt-6 justify-center md:justify-start">
              <a
                href="https://spiralenergy.vercel.app/"
                target={"_blank"}
                className="w-[250px] rounded-full drop-shadow-md flex items-center justify-center font-semibold cursor-pointer border-gray-200 text-center bg-gradient-to-r from-[#110613] to-[#1e191f] "
              >
                <div className="text-white font-light px-3 py-3">
                  Get started
                </div>
              </a>
            </div>
          </div>
        ))}
      </Slider>
    );
  };

  return (
    <Layout>
      {shownotice && (
        <ChangeNameModal showmodal={shownotice} setshowmodal={setshownotice} />
      )}
      <div className="pb-[100px] ">
        <div
          className="h-screen absolute top-[100px] left-0 w-screen"
          style={{
            background: `url(${BGImg})`,
            backgroundPosition: "center",
            backgroundRepeat: "center",
            backgroundSize: "cover",
            opacity: 0.3,
            backgroundAttachment: "fixed",
          }}
        ></div>
        <Slider {...settings}>
          {solutions.map(({ id, title, desc }) => (
            <div
              // mt-[100px]
              className="md:h-[90vh] lg:h-[90vh] pt-[80px] w:pt-[40px] md:mt-0"
              key={id}
            >
              <div className="h-screen grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 w-[90%] md:w-[83%] lg:w-[50%] mx-auto items-center justify-center ">
                <div className="flex items-center justify-center flex-col mb-6 pt-[70px]">
                  {id == 46 ? (
                    <>
                      <div className="text-[35px] md:text-[40px] text-center font-bold">
                        {title} <br />
                        <div className="flex flex-wrap items-center justify-center">
                          <div className="text-[#C41230] font-bold font-DMSerifDisplay">
                            Carnegie Mellon{" "}
                          </div>{" "}
                          <div className="hidden items-center justify-center  px-2"></div>
                        </div>
                      </div>
                    </>
                  ) : id == 1 ? (
                    <>
                      <div className="text-[35px] md:text-[40px] text-center font-bold">
                        {title} <br />
                        <div className="flex flex-wrap items-center justify-center">
                          <div className="text-[#C41230] font-bold font-DMSerifDisplay">
                            {t("nav.grid")}
                          </div>{" "}
                          <div className="hidden items-center justify-center  px-2"></div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="text-[35px] md:text-[40px] w-full text-center font-bold">
                      {title}
                    </div>
                  )}
                  <div className="text-[20px] pt-8 font-light text-center w-full">
                    {desc}
                  </div>
                  <div className="grid grid-cols-1 mt-12 w-[60%] md:w-[40%] mx-auto items-center justify-items-center">
                    {/* <div className="border-2 border-grey-500 w-[90%] p-4 rounded-md text-white text-center">
                      Speak with Us
                    </div> */}
                    <Link
                      to="https://calendly.com/hello-dmea/30min"
                      target="_blank"
                      className="border-2 cursor-pointer border-blue-500 bg-blue-500 w-[70%] p-3 rounded-md text-white text-center"
                    >
                      {t("nav.speak")}
                    </Link>
                  </div>
                  <div className="hidden w-full items-center justify-start mt-6">
                    <div className="flex md:w-[70%] lg:w-[60%] border-[1px] border-black items-center justify-between p-1 rounded-full bg-white">
                      <div className="w-[70%]">
                        <input
                          className="w-[95%] placeholder:font-thin px-2 outline-none border-none"
                          type={"text"}
                          placeholder="Enter you email address..."
                        />
                      </div>
                      <div className="bg-gray-900 rounded-full cursor-pointer">
                        <div className="text-white font-light px-4 py-2 text-md text-[14px] md:text-[16px]">
                          Send mail
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="md:h-full lg:h-full flex items-center justify-center h-[500px]">
                  {/* bg-gradient-to-r from-blue-400 to-blue-500 */}
                  <div className="h-[80%] w-[85%] rounded-lg mx-auto">
                    {/* <img src={CMUImg} className="w-full h-full" /> */}
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* <div className=" h-[95vh] w-[95%] bg-green-500 grid grid-cols-2 border-[2px] border-red-400 mx-auto"></div> */}
        </Slider>

        {/* hero */}
        <div className="bg-gradient-to-tr from-[#e0c3fc40] hidden to-[#8ec5fc34]">
          <div className="h-auto sm:pt-16 lg:pb-20 w-full md:w-[90%] mx-auto grid gap-4 md:gap-10 grid-cols-1 md:grid-cols-1 lg:grid-cols-2 p-3 ">
            {/* <div className="w-full rounded-lg pt-[50px] p-5 flex items-center justify-center border-2 border-red-500"> */}
            <div className=" lg:h-[600px] mt-4 w-full min-h-[20vw]">
              <TestSlider />
              {/* <Slider /> */}
              <div className="lg:pt-30 hidden">
                <div className="text-left pt-36 font-Kanit font-semibold text-[40px] leading-[55px] text-gray-800">
                  Lowest CAPEX + Lowest OPEX + Most Durable Meters = Most Viable
                  Grids
                </div>
                <div className=" text-gray-800 pb-3 text-[18px] pt-3 font-light text-start">
                  You can’t find better pricing elsewhere as we offer you the
                  units at cost.
                </div>
                <div className="grid pb-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-2 border-1 border-teal-900 gap-5 place-content-start mt-10 md:w-[70%]">
                  <a
                    href="https://spiralenergy.vercel.app/"
                    target={"_blank"}
                    className="w-100 rounded-full drop-shadow-md flex items-center justify-center font-semibold cursor-pointer border-gray-200 text-center bg-gradient-to-r from-[#110613] to-[#1e191f] "
                  >
                    <div className="text-white font-light px-3 py-3">
                      Get started
                    </div>
                  </a>
                  <div className="border-[1px] hidden drop-shadow-sm bg-white font-light cursor-pointer border-gray-200 px-3 py-3 rounded-[30px] text-center text-gray-800">
                    Contact Sales
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden">
              <Slider />
            </div>
            {/* </div> */}
            <div className="mt-4 ">
              <div className="w-full lg:h-[600px] bordder-[1px] border-red-500 flex items-center justify-center rounded-lg ">
                <img src={HeroView} alt="" className="" />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-20 w-[90%] mx-auto bg-white border rounded-md hidden grid-cols-1 md:grid-cols-2 lg:grid-cols-2  gap-8 gain-bg ">
          <div className="w-100 flex items-center justify-center rounded-lg ">
            <img src={TabView} alt="" className="" />
          </div>
          <div className="flex flex-col items-center justify-center mb-10 mt-20 mx-auto w-[80%]">
            <div className="text-3xl text-center font-semibold mx-2 text-white font-Kanit ">
              Gain full control
            </div>
            <div className="pt-2 text-center text-white font-">
              A mordern approach to energy efficiency.
            </div>
            <div className="pt-2 text-center text-[17px] text-white">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type .
            </div>
          </div>
        </div>

        <div className="h-auto hidden">
          <div className="bg-gradient-to-br from-[#281a39] to-[#1d0d43] px-3 h-[300px] flex items-center justify-center">
            <div className="flex flex-col items-center justify-center mb-10 w-[90%]">
              <div className="text-3xl font-semibold mx-2 text-white font-Kanit text-start">
                Our Meters in Services.
              </div>
              <div className="pt-2 text-center font-light text-white">
                Lorem Ipsum is simply dummy text of the printing and typesetting
              </div>
            </div>
          </div>
          <div className="w-[90%] md:w-[80%] lg:w-[80%] mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 place-content-center relative top-[-50px] place-items-center ">
            {/* service */}
            <CountCard title={"Meters in Service"} value={"1003"} />
            {/* mini/macro grid */}
            <CountCard title={"Mini/Macro grid Meters"} value={"281"} />
            {/* users grid */}
            <CountCard title={"Users Meters"} value={"493"} />
            {/* customers grid */}
            <CountCard title={"Custom Meter"} value={"304"} />
          </div>
        </div>
        {/* Spiral Meter */}
        <div className="hidden grid-cols-1 md:grid-cols-2 lg:grid-cols-2 w-[90%] mx-auto  mt-20 gap-10">
          <div className="w-[90%] flex-col flex justify-center ">
            <div className="font-light text-gray-700">
              Lorem Ipsum is simply dummy text of the printing and typesetting
            </div>{" "}
            <div className="font-Kanit text-3xl font-semibold">
              Lorem Ipsum is simply dummy text of the printing and typesetting
            </div>
            <div className="pt-3 font-light text-gray-700">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              Lorem Ipsum is simply dummy text of the printing and typesetting
              Lorem Ipsum is simply dummy text of the printing and typesetting
              Lorem Ipsum is simply dummy text of the printing and typesetting
              Lorem Ipsum is simply dummy text of the printing and typesetting
              Lorem Ipsum is simply dummy text of the printing and typesetting
            </div>
          </div>
          <div className="video-responsive w-full">
            <iframe
              style={{}}
              height="500"
              src={`https://www.youtube.com/embed/rokGy0huYEA`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Landing;
