import "./style.css";
import { IoMdClose } from "react-icons/io";
import { Link } from "react-router-dom";
import ContactModal from "../ContactModal";
import { useTranslation } from "react-i18next";
import ContactDoc from "../../assets/docs/call_to_partner.pdf";

const Modal = ({ closeModal, showmodal, setshowmodal }) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="modal-nav drop-shadow-lg" onClick={() => closeModal(false)}>
      <div
        className="modal-container bg-white px-4 pt-4 pb-10"
        onClick={() => closeModal(false)}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div className="w-[30px] hidden h-[30px] rounded-full overflow-hidden items-center justify-center border-[1px] border-secondary"></div>
            <Link
              to={"/"}
              className="font-semibold text-[18px] text-zinc-900 font-Kanit pt-1"
              style={{
                color: "",
              }}
            >
              Chanels
            </Link>
          </div>
          <div className="cursor-pointer" onClick={() => closeModal(false)}>
            <IoMdClose size={28} color={""} />
          </div>
        </div>
        <div className="">
          <Link to="/" className="pr-5 block py-2 my-3">
            {/* Home */}
            {t("nav.home")}
          </Link>
          <Link
            // target="_blank"
            to="/features"
            className="pr-5 block py-2 my-3"
          >
            {t("nav.developer")}
          </Link>

          <a target="_blank" href={ContactDoc} className="pr-5 block py-2 my-3">
            {t("nav.calltopartner")}
          </a>
          <div
            onClick={() => {
              closeModal(false);
              setshowmodal(true);
            }}
            target="_blank"
            className="pr-5 block py-2 my-3"
          >
            {t("nav.contact")}
          </div>
          {/* <Link to="/services" className="pr-5 block py-2 my-3">
            Our Value proposition
          </Link>
          <Link to="/blog" className="pr-5 block py-2 my-3">
            Blog
          </Link> */}
          <Link to="/sectors" className="pr-5 hidden py-2 my-3">
            Contact
          </Link>
        </div>
        <div className="flex items-center">
          <a
            href="https://app.chanels.io/"
            className={
              "border-[1px] mr-4 border-blue-500 text-blue-500 text-light px-4 py-2 rounded-md cursor-pointer"
            }
          >
            {t("nav.login")}
          </a>
          <a
            href="https://app.chanels.io/createaccount"
            // target={"_blank"}
            className={
              "bg-blue-500 text-white w-[fit-content] px-4 py-2 rounded-md cursor-pointer"
            }
          >
            {t("nav.getstarted")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Modal;
